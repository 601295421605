import {
  forwardRef,
  DetailedHTMLProps,
  InputHTMLAttributes,
  SelectHTMLAttributes,
} from "react"
import { useStyle } from "../../../hooks/use-style"
import { lightTheme, darkTheme } from "../../../services/theme"
import React from "react"

type Props = DetailedHTMLProps<
  SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  valid?: boolean
}

const Select = forwardRef<HTMLSelectElement, Props>(
  ({ valid: isValid = true, ...props }, ref) => {
    const css = useStyle(
      theme => ({
        backgroundColor:
          theme === "light"
            ? lightTheme.color.background.secondary
            : darkTheme.color.background.primary,
        borderColor:
          theme === "light"
            ? isValid
              ? lightTheme.color.border
              : lightTheme.color.error
            : isValid
            ? darkTheme.color.border
            : lightTheme.color.error,
        focusColor: !isValid
          ? "transparent"
          : theme === "light"
          ? lightTheme.color.active
          : darkTheme.color.active,
      }),
      [isValid]
    )

    return (
      <div className="row">
        <select ref={ref} {...props} />

        <style jsx>{`
          .row {
            display: flex;
            align-items: center;
          }
          select {
            font: inherit;
            background-color: ${css.backgroundColor};
            border: 1px solid ${css.borderColor};
            border-radius: 0.3em;
            color: inherit;
            padding: 0.5em 1em;
            transition: 200ms background-color, 200ms border-color,
              200ms box-shadow;
            min-width: 0;
            width: 100%;
          }
          select:focus {
            box-shadow: 0 0 0 1px ${css.focusColor};
          }
        `}</style>
      </div>
    )
  }
)

export default Select
